<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div class="fn24-weight7 text-center" style="color: #1a347f">
          Digital Assets Hubs
        </div>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          dense
          rounded
          hide-details
          v-model="nameMenu"
          :items="['Technical Knowledge', 'Digital Assets Hubs']"
          class="pa-2"
          style="border: 1px solid #07204b"
        />
      </v-col>
      <v-col cols="12">
        <v-card class="mx-auto" height="400" elevation="0">
          <v-navigation-drawer permanent width="500">
            <a-menu mode="inline" theme="light">
              <a-sub-menu v-for="item in NavigatorDefault" :key="item.key">
                <span slot="title" class="fn22">
                  <span>{{ item.headerName }}</span>
                </span>
                <a-menu-item
                  v-for="menuList in item.menuItem"
                  :key="menuList.key"
                  @click="ChangePage(menuList)"
                  class="fn20"
                  style="color: #1a347f"
                >
                  {{ menuList.text }}
                </a-menu-item>
              </a-sub-menu>
            </a-menu>
          </v-navigation-drawer>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      nameMenu: "Technical Knowledge",
      NavigatorDefault: [
        {
          headerName: "Parasiticide (4)",
          menuItem: [
            {
              key: "NexGardFamily",
              text: "NexGard Family",
              path: "/ManageNexPOSM",
              name: "ManageNexPOSM",
            },
            {
              key: "FRONTLINE",
              text: "FRONTLINE",
              // path: "/ManageNexPOSM",
              // name: "ManageNexPOSM",
            },
            {
              key: "Broadline",
              text: "Broadline",
              // path: "/ManageNexPOSM",
              // name: "ManageNexPOSM",
            },
            {
              key: "Heartgard",
              text: "Heartgard",
              // path: "/ManageNexPOSM",
              // name: "ManageNexPOSM",
            },
          ],
        },
        {
          headerName: "Therapeutic (5)",
          // menuItem: [
          //   {
          //     key: "NexGardFamily",
          //     text: "NexGard Family",
          //     path: "/ManageNexPOSM",
          //     name: "ManageNexPOSM",
          //   },
          //   {
          //     key: "FRONTLINE",
          //     text: "FRONTLINE",
          // path: "/ManageNexPOSM",
          // name: "ManageNexPOSM",
          //   },
          //   {
          //     key: "Broadline",
          //     text: "Broadline",
          // path: "/ManageNexPOSM",
          // name: "ManageNexPOSM",
          //   },
          //   {
          //     key: "Heartgard",
          //     text: "Heartgard",
          // path: "/ManageNexPOSM",
          // name: "ManageNexPOSM",
          //   },
          // ],
        },
        {
          headerName: "Vaccine (3)",
          // menuItem: [
          //   {
          //     key: "NexGardFamily",
          //     text: "NexGard Family",
          //     path: "/ManageNexPOSM",
          //     name: "ManageNexPOSM",
          //   },
          //   {
          //     key: "FRONTLINE",
          //     text: "FRONTLINE",
          // path: "/ManageNexPOSM",
          // name: "ManageNexPOSM",
          //   },
          //   {
          //     key: "Broadline",
          //     text: "Broadline",
          // path: "/ManageNexPOSM",
          // name: "ManageNexPOSM",
          //   },
          //   {
          //     key: "Heartgard",
          //     text: "Heartgard",
          // path: "/ManageNexPOSM",
          // name: "ManageNexPOSM",
          //   },
          // ],
        },
      ],
      search: "",
      count: 0,
    };
  },
  // created() {
  //   this.getAllskills();
  // },
  methods: {
    async getAllskills() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/skills`
        // 'http://localhost:8081/categories/'
      );
      console.log("getAllskills", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    async ChangePage(val) {
      if (val.name !== this.$router.currentRoute.name) {
        this.$router.push({ path: val.path }).catch(() => {});
      }
    },
    goToPOSM(item) {
      console.log(item);
      this.$router.push("ManageNexPOSM");
    },
    Updateskills(val) {
      localStorage.setItem("skillsdata", Encode.encode(val));
      this.$router.push("Editskills");
    },
    Viewskills(val) {
      localStorage.setItem("skillsdata", Encode.encode(val));
      this.$router.push("Viewskills");
    },
    async Deleteskills(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userBiData"))
          );
          const auth = {
            headers: {
              Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
            },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/skills/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllskills();
        }
      });
    },
    goToCreate() {
      this.$router.push("createskills");
    },
  },
};
</script>
